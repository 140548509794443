<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData();
        "
      >
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="配置ID:">
                <el-input v-model="formInline.invoiceSettingId" placeholder="请输入准确信息" />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="formInline.selectContent"
                  placeholder="请输入准确信息"
                  style="margin-top: 4px"
                >
                  <el-select
                    slot="prepend"
                    v-model="formInline.selectType"
                    style="width: 120px; position: relative; top: -1px; left: -1px"
                  >
                    <el-option :label="$t('searchModule.Merchant_Name')" :value="1" />
                    <el-option label="销货方名称" :value="2" />
                    <el-option label="销货方识别号" :value="3" />
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="发票平台:">
                <el-select v-model="formInline.invoiceChannel">
                  <el-option label="全部" value="" />
                  <el-option label="航信" :value="0" />
                  <el-option label="航信诺诺" :value="2" />
                  <el-option label="诺诺数电" :value="3" />
                  <el-option label="百旺" :value="1" />
                  <el-option label="百旺云" :value="4" />
                </el-select>
              </el-form-item>
              <el-form-item label="状态:">
                <el-select v-model="formInline.state" multiple collapse-tags clearable>
                  <el-option label="已保存" :value="0" />
                  <el-option label="已启用" :value="1" />
                  <el-option label="已停用" :value="2" />
                </el-select>
              </el-form-item>
              <el-form-item label="最后修改时间:">
                <el-date-picker
                  v-model="timeArray"
                  type="datetimerange"
                  range-separator="至"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</AuthorityComponent
              >
              <el-button icon="el-icon-delete" @click="reset">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.create']"
                type="primary"
                @click="$router.push({ path: '/invoiceManage-AE' })"
                >创建配置</AuthorityComponent
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper paddingB10 bgFFF">
        <el-table v-loading="loading" :data="tableData" style="width: 100%; font-size: 10px">
          <el-table-column :label="$t('searchModule.Configure_ID')" align="center" prop="id" />
          <el-table-column :label="$t('searchModule.Merchant_Name')" align="center" prop="operationName" />
          <el-table-column :label="$t('searchModule.Invoice_platform')" align="center" prop="invoiceChannel">
            <template slot-scope="scope">
              <span v-if="scope.row.invoiceChannel === 0">航信</span>
              <span v-if="scope.row.invoiceChannel === 2">航信诺诺</span>
              <span v-if="scope.row.invoiceChannel === 1">百旺</span>
              <span v-if="scope.row.invoiceChannel === 3">诺诺数电</span>
              <span v-if="scope.row.invoiceChannel === 4">百旺云</span>
            </template>
          </el-table-column>
          <el-table-column label="开票项目" align="center" prop="groupItems" />
          <el-table-column label="销货方名称" align="center" prop="sellerName" />
          <el-table-column label="销货方识别号" align="center" prop="sellerTaxpayerNumber" />
          <el-table-column label="开票人" align="center" prop="drawer" />
          <el-table-column :label="$t('searchModule.state')" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.state === 0">已保存</span>
              <span v-else-if="scope.row.state === 1" style="color: #67c23a">已启用</span>
              <span v-else style="color: red">已停用</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.last_Operator')" align="center" prop="operateName" />
          <el-table-column :label="$t('searchModule.last_Change_time')" align="center" prop="updateTime" sortable />
          <el-table-column
            label="操作"
            width="80"
            align="center"
            v-if="
              $route.meta.authority.button.invoiceSetting ||
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.manager
            "
          >
            <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                @command="handleCommand($event, scope.row)"
              >
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.create']"
                    command="1"
                    >详情</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.enable']"
                    v-if="scope.row.state === 0"
                    command="2"
                    >启用</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.disable']"
                    v-if="scope.row.state === 1"
                    command="3"
                    >停用</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.edit']"
                    v-if="scope.row.state === 0"
                    command="4"
                    >编辑</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.delete']"
                    v-if="scope.row.state === 0"
                    command="5"
                    >删除</AuthorityComponent
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import * as utils from "@/common/js/utils"
// import { dateFormat, setIndex } from "@/common/js/public.js"
export default {
  name: "invoiceManage",
  data() {
    return {
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      tableCols: [
        {
          prop: "id",
          label: "配置ID",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
        },
        {
          prop: "invoiceChannel",
          label: "发票平台",
          formatter: (value, row, col) => {
            if (col === 0) {
              return "航信";
            } else {
              return "百旺";
            }
          },
        },
        {
          prop: "groupItems",
          label: "开票项目",
        },
        {
          prop: "sellerName",
          label: "销货方名称",
        },
        {
          prop: "sellerTaxpayerNumber",
          label: "销货方识别号",
        },
        {
          prop: "drawer",
          label: "开票人",
        },
        {
          prop: "state",
          label: this.$t("list.state"),
          formatter: (value, row, col) => {
            if (col === 0) {
              return "已保存";
            } else if (col === 1) {
              return "已启用";
            } else {
              return "已停用";
            }
          },
        },
        {
          prop: "operateName",
          label: "最后操作人",
        },
        {
          prop: "updateTime",
          label: "最后修改时间",
          sortable: true,
        },
      ],
      tableData: [],
      formInline: { selectType: 1, state: [] },
      timeArray: [],
    };
  },
  methods: {
    // 分页处理
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showDetail(index, obj) {
      this.$router.push({
        path: "/merchantManagerAE",
        query: obj,
      });
    },
    // 操作细则
    handleCommand(command, row) {
      if (command == 1) {
        this.$router.push({ path: "/invoiceManageDetail", query: { invoiceSettingId: row.id } });
      } else if (command == 2) {
        this.changeState(1, row.id);
      } else if (command == 3) {
        this.changeState(2, row.id);
      } else if (command == 4) {
        this.$router.push({ path: "/invoiceManage-AE", query: { invoiceSettingId: row.id } });
      } else if (command == 5) {
        this.deleteItem(row.id);
      }
    },
    // 启用和禁用
    changeState(type, invoiceSettingId) {
      let url = "";
      if (type === 1) {
        url = "/acb/2.0/invoiceSetting/onLine";
      } else {
        url = "/acb/2.0/invoiceSetting/offLine";
      }
      const opt = {
        url,
        method: "get",
        data: { invoiceSettingId },
        success: (res) => {
          this.$alert("操作成功", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.searchData();
        },
      };
      this.$request(opt);
    },
    // 删除
    deleteItem(invoiceSettingId) {
      this.$confirm("是否确认删除?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const opt = {
            url: "/acb/2.0/invoiceSetting/delete",
            method: "get",
            data: { invoiceSettingId },
            success: (res) => {
              this.$alert("操作成功", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              this.searchData();
            },
          };
          this.$request(opt);
        })
        .catch((err) => {});
    },
    // 管理用户
    // managerUser(index, obj) {
    //   this.$router.push({
    //     path: "/operatorAdministratorsManage",
    //     query: obj
    //   })
    // },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      const tempData = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      if (this.formInline.invoiceSettingId) {
        tempData.invoiceSettingId = this.formInline.invoiceSettingId;
      }
      if (this.formInline.selectContent) {
        const type = this.formInline.selectType;
        if (type === 1) {
          tempData.operationName = this.formInline.selectContent;
        } else if (type === 2) {
          tempData.sellerName = this.formInline.selectContent;
        } else if (type === 3) {
          tempData.sellerTaxpayerNumber = this.formInline.selectContent;
        }
      }
      if (
        this.formInline.invoiceChannel === 0 ||
        this.formInline.invoiceChannel === 1 ||
        this.formInline.invoiceChannel === 2 ||
        this.formInline.invoiceChannel === 3 ||
        this.formInline.invoiceChannel === 4
      ) {
        tempData.invoiceChannel = this.formInline.invoiceChannel;
      }
      if (this.formInline.state && this.formInline.state.length > 0) {
        tempData.state = this.formInline.state.join(",");
      }
      if (this.timeArray && this.timeArray.length > 0) {
        tempData.startDate = this.$moment(this.timeArray[0]).format("YYYY-MM-DD HH:mm:ss");
        tempData.endDate = this.$moment(this.timeArray[1]).format("YYYY-MM-DD HH:mm:ss");
      }
      console.log(tempData);
      this.$axios
        .get("/acb/2.0/invoiceSetting/query", {
          data: tempData,
        })
        .then((res) => {
          this.tableData = res.value.list;
          this.total = res.value.total * 1 || 0;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    reset() {
      this.formInline = { selectType: 1 };
      this.timeArray = [];
      this.searchData();
    },
    /* 商户名称的下拉框 */
    // getRoleList() {
    //   this.$axios.get("/acb/2.0/operation/nameList").then(res => {
    //     this.loading = false
    //     if (res.state == 0) {
    //       this.roleList = res.value
    //     } else {
    //       this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //         confirmButtonText: this.$t('pop_up.Determine')
    //       })
    //     }
    //   })
    // }
  },
  // beforeDestroy() {
  //   sessionStorage.searchData = JSON.stringify(this.formInline);
  //   sessionStorage.searchFlag = 1;
  // },
  activated() {
    this.searchData();
  },
  created() {
    // if (sessionStorage.searchFlag == 1) {
    //   this.formInline = JSON.parse(sessionStorage.searchData);
    // }
    this.searchData();
    // this.getRoleList();
    // let xxx = utils.portalHasPage("/operatorAdministratorsManage");
    // this.buttonX = xxx;
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
